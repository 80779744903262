:root {
  --background: #ffffff;
  --contrast: #4292c6;
  --contrast1: #ef3b2c;
  --contrast2: #2c3531;
  --font-heading: 'Montserrat', sans-serif;
  --font-text: 'Quicksand', sans-serif;
  --font-weight-heading: 500;
  --font-size-content: 20px;
}

.app{
  background-color: var(--background) !important;
}

.section{
  padding-top: 15vh;
  padding-bottom: 15vh;
  margin-left: 0% !important;
  color: var(--contrast2);
}
.section-heading{
  font-size: 3rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: var(--font-weight-heading);
  max-width: 100%;
  color: var(--contrast);
}
.section-content{
  margin-top: 2vh !important;
  max-width: 100%;
  font-family: var(--font-text);
  font-size: var(--font-size-content);
}
@media only screen and (max-width: 768px) {
  .container{
    margin-left: 5%;
    max-width: 95%;
  }
  .section{
    padding-top: 7vh;
    padding-bottom: 7vh;
    width: 95%;
  }
}

a{
  color: var(--contrast1);
  text-decoration: none;
}
a:hover {
  color: var(--contrast) !important;
}
